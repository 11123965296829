<template>
  <v-app class="margin-tool-bary">
    <v-app-bar color="primary" dense dark fixed max-height="46">
      <v-app-bar-nav-icon icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        {{ setTitleNavBar() }}
      </v-toolbar-title>
      <v-spacer />
      <!-- lazy-src="https://webadmin.capital28.investments/static/img/capital28-logo-blanco.png" -->

      <div class="center-me">
        <v-img
          v-if="realEstateGroupLogo"
          max-height="36"
          max-width="200"
          contain
          :src="realEstateGroupLogo"
        ></v-img>
      </div>
      <v-spacer />
      <component v-if="toolbarLayout" v-bind:is="toolbarLayout"></component>
      <!-- icono menú lateral derecho de filtros -->
      <v-app-bar-nav-icon v-if="filterLayout" @click.stop="openLeadsFilter()">
        <div>
          <v-btn icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <div v-if="someFilterSelected">
                  <v-icon color="amber" class="filter-glow" v-on="on"
                    >mdi-tune</v-icon
                  >
                </div>
                <div v-else>
                  <v-icon v-on="on">mdi-tune</v-icon>
                </div>
              </template>
              <span>Filtros</span>
            </v-tooltip>
          </v-btn>
        </div>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      temporary
      v-model="drawer"
      app
      color="blue-grey darken-2"
      dark
      width="240"
      :src="`${publicPath}sidebar-bg.png`"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-menu
            offset-y
            v-model="menuSettings"
            :close-on-click="false"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on }">
              <v-hover>
                <v-chip
                  slot-scope="{ hover }"
                  :class="`elevation-${hover ? 5 : 0}`"
                  v-ripple
                  color="transparent"
                  text-color="white"
                  label
                  v-on="on"
                >
                  <v-avatar>
                    <img width="32px" height="32px" :src="userImageSrc" />
                  </v-avatar>
                  <v-icon>
                    mdi-chevron-down
                  </v-icon>
                </v-chip>
              </v-hover>
            </template>
            <v-card elevation="0" min-width="400" max-width="400">
              <v-list v-if="isLoadingUserData">
                <v-list-item>
                  <v-list-item-avatar>
                    <img
                      :src="userImageSrc"
                      alt="Imagen / Foto de perfil de Usuario"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      Obteniendo Nombre ...
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Obteniendo datos ...
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon loading>
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="userImageSrc" alt="John" />
                  </v-list-item-avatar>

                  <v-list-item-content v-if="userData">
                    <v-list-item-title v-if="userData.name">
                      {{ userData.name }}
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      No se encontró el Nombre
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="userData.email">
                      {{ userData.email }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>
                      No se encontró el Email
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-title>
                      No se encontró el Nombre
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      No se encontró el Email
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action v-if="userRole !== 'developer'">
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :loading="isLoadingUserData"
                          color="primary"
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ getLeadsPerformance(userData).total }}
                        </v-btn>
                      </template>
                      <div>
                        <div>
                          Total de Leads:
                          <span class="light-blue--text font-weight-bold">
                            {{ getLeadsPerformance(userData).total }}
                          </span>
                        </div>
                        <div>
                          Leads Activos:
                          <span class="success--text font-weight-bold">
                            {{ getLeadsPerformance(userData).active }}
                          </span>
                        </div>
                      </div>
                    </v-tooltip>
                  </v-list-item-action>
                  <v-list-item-action v-if="userRole !== 'developer'">
                    <v-tooltip bottom color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :loading="isLoadingUserData"
                          color="primary"
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ getLeadsPerformance(userData).defeated }}
                        </v-btn>
                      </template>
                      <div>
                        <div>
                          Cantidad de Leads que requieren atención
                        </div>
                      </div>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item
                  v-for="(item, index) in optionsSettings"
                  :key="index"
                >
                  <v-list-item-action>
                    <v-icon>
                      {{ item.prefixIcon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>
                    <v-btn
                      block
                      :loading="isLoadingUserData"
                      color="transparent"
                      class="caption text-capitalize"
                      @click="item.toMethod(userData)"
                    >
                      {{ item.title }}
                      <v-spacer />
                      <v-icon right dark>
                        {{ item.appendIcon }}
                      </v-icon>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-btn
                  text
                  color="red"
                  class="caption text-capitalize"
                  @click="logOut"
                >
                  Cerrar Sesión
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn icon @click="getContactsList()">
                  <v-icon>
                    mdi-sync
                  </v-icon>
                </v-btn>
                <v-btn color="primary" icon @click="menuSettings = false">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon color="red lighten-2" v-on:click="logOut">
                mdi-power
              </v-icon>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <component v-bind:is="navLayout"></component>
    </v-navigation-drawer>

    <v-main class="pa-0">
      <v-container class="pa-0" fluid>
        <slot />
      </v-container>
    </v-main>

    <v-navigation-drawer
      v-model="right"
      fixed
      right
      temporary
      color="blue-grey lighten-5"
    >
      <component v-if="filterLayout" v-bind:is="filterLayout"></component>
    </v-navigation-drawer>

    <lead-advanced-create-form
      v-if="
        userRole !== 'coordinator' &&
          userRole !== 'developer' &&
          userRole !== 'marketing' &&
          userRole !== 'marketingv2' &&
          showBtnCreateLead
      "
      ref="leadCreateComponent"
      @close-menu="onCloseDialogCreateLead"
    />
    <details-user-modal
      :visible="showUpdateUserModal"
      :user-to-update="userToUpdate"
      @close="onCloseUserToUpdatetModal"
    />
    <vue-snotify></vue-snotify>
    <modal-information :dialog="dialog" :messageServer="serverMessage" />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { LogOut, VerifyToken } from "@/api/user-api.js";
import ModalInformation from "../layouts/nav/ModalInformation.vue";

export default {
  props: {
    source: String
  },
  components: {
    //Tiene el anterior registro
    LeadAdvancedCreateForm: () =>
      import("@/components/lead/LeadAdvancedCreateForm.vue"),
    AdminNav: () => import("@/components/layouts/nav/AdminNav"),
    DeveloperNav: () => import("@/components/layouts/nav/DeveloperNav"),
    BrokerNav: () => import("@/components/layouts/nav/BrokerNav"),
    RootNav: () => import("@/components/layouts/nav/RootNav"),
    MarketingNav: () => import("@/components/layouts/nav/MarketingNav"),
    MarketingV2Nav: () => import("@/components/layouts/nav/MarketingV2Nav"),
    CoordinatorNav: () => import("@/components/layouts/nav/CoordinatorNav"),

    LeadsFilter: () => import("@/components/layouts/filters/LeadsFilter"),
    PropertiesFilter: () =>
      import("@/components/layouts/filters/PropertiesFilter"),
    LeadsToolbar: () => import("@/components/layouts/toolbar/LeadsToolbar"),
    DetailsUserModal: () => import("@/components/user/UserUpdate.vue"),
    ModalInformation
  },
  filters: {
    shortName(n) {
      return n.substring(0, 12);
    }
  },
  data: () => ({
    drawer: false,
    leadsStatsLoading: true,
    menuSettings: false,
    right: false,
    isLoadingUserData: true,
    publicPath: process.env.BASE_URL,
    navLayout: "",
    filterLayout: "",
    toolbarLayout: "",
    collapseOnScroll: true,
    contactsList: null,
    userData: null,
    userRole: localStorage.getItem("user_role") || "",
    userImage: localStorage.getItem("user_image") || "",
    showUpdateUserModal: false,
    userToUpdate: null,
    showBtnCreateLead: false,
    dialog: false,
    serverMessage: ""
  }),
  watch: {
    $route(newValue) {
      // actualizar la barra de filtros al cambiar la ruta
      this.filterLayout = this.setFilterLayout();
      this.toolbarLayout = this.setToolbarLayout();
      this.mostrarBtnCreateLead(newValue);
    },
    right(newValue) {
      if (!newValue) {
        this.closeLeadsFilter();
      }
    }
  },
  computed: {
    ...mapState({
      preferencesSt: state => state.preferences,
      filtersSt: state => state.leads.filters,
      contactsListLoadingSt: state => state.contacts.ContactsListLoading,
      contactListSt: state => state.contacts.contactList,
      leadsTableFooterSt: state => state.leads.leadsTableFooter,
      filtersSelectedSt: state => state.leads.filtersSelected,
      filtersSelectedChangedSt: state => state.leads.filtersSelectedChanged,
      someFilterSelected: state => state.leads.filterSomeFilterSelected
    }),
    userName() {
      return this.$store.getters.userName;
    },
    optionsSettings: function() {
      return [
        {
          title: "Mi cuenta",
          prefixIcon: "mdi-account",
          appendIcon: "mdi-cog-outline",
          switch: false,
          toMethod: item => this.showDetails(item)
        }
      ];
    },
    realEstateGroupLogo() {
      try {
        if (this.$store.getters.realEstateGroupLogo) {
          return this.$store.getters.realEstateGroupLogo;
        } else {
          return require("@/assets/logo-capital28-blanco.png");
        }
      } catch (error) {
        return require("@/assets/logo-capital28-blanco.png");
      }
    },
    userImageSrc() {
      let contactImage = this.userImage;
      if (contactImage) return contactImage;
      contactImage = "https://dummyimage.com/44x44/ffffff/2e394a&text=28";
      return contactImage;
    },
    isLeadKanbanView() {
      return this.$route.path == "/admin/lead/kanban";
    },
    isLeadTableView() {
      return (
        this.$route.path == "/admin/lead" ||
        this.$route.path == "/admin/lead/" ||
        this.$route.path == "/admin/lead/list" ||
        this.$route.path == "/developer/lead" ||
        this.$route.path == "/developer/lead/" ||
        this.$route.path == "/developer/lead/list" ||
        this.$route.path == "/broker/lead" ||
        this.$route.path == "/broker/lead/" ||
        this.$route.path == "/broker/lead/list"
      );
    }
  },
  async mounted() {
    await VerifyToken();
    this.getContactsList();
    this.mostrarBtnCreateLead(this.$route);
  },
  methods: {
    ...mapMutations({
      setLeadsTableFooterSt: "leads/setLeadsTableFooter",
      setFiltersSelectedSt: "leads/setFiltersSelected",
      setFiltersSelectedChangedSt: "leads/setFiltersSelectedChanged",
      setLeadsPaginationClearSt: "leads/setLeadsPaginationClear",
      setClearFiltersSt: "leads/setClearFilters",
      setFilteredLeadsSearched: "leads/setFilteredLeadsSearched",
      setSelectedItems: "leads/setSelectedItems"
    }),
    ...mapActions({
      fetchContactStore: "contacts/fetchContactList",
      getUserByIdStore: "getUserInfobyId",
      fetchLeadsleadsPaginationSt: "leads/fetchLeadsPagination",
      fetchGetLeadsKanbanByPhaseSt: "leads/fetchGetLeadsKanbanByPhase"
    }),
    mostrarBtnCreateLead(route) {
      if (route && route.path) {
        const path = route.path;
        let routesAcepted = ["leads", "external-broker"];
        this.showBtnCreateLead = routesAcepted.some(keyword =>
          path.includes(keyword)
        );
      } else {
        this.showBtnCreateLead = false;
      }
    },
    openLeadsFilter() {
      this.setFiltersSelectedChangedSt(false);
      this.right = !this.right;
    },
    closeLeadsFilter() {
      if (this.preferencesSt && this.preferencesSt.showKanbanFirst) {
        this.setClearFiltersSt(false);
        this.filterLeadsKanban();
      } else {
        this.setClearFiltersSt(false);
        this.filterLeadsTable();
      }
    },
    filterLeadsTable() {
      if (this.filtersSelectedChangedSt) {
        this.setFilteredLeadsSearched(false);
        this.setSelectedItems([]);
        this.setLeadsTableFooterSt(null);
        // Este codigo al poner el limite en 25
        // genera el error de las keys duplicadas
        this.fetchLeadsleadsPaginationSt({
          filter: this.filtersSelectedSt,
          page: 0,
          limit: 10,
          footer: this.leadsTableFooterSt
        });
      }
    },
    filterLeadsKanban() {
      if (this.filtersSelectedChangedSt) {
        this.setSelectedItems([]);
        this.setFilteredLeadsSearched(false);
        this.fetchGetLeadsKanbanByPhaseSt({
          filter: this.filtersSelectedSt,
          page: 0,
          phases: this.filtersSt.phases
        });
      }
    },
    onCloseDialogCreateLead() {
      this.$refs.leadCreateComponent.closeEverthing();
    },
    getContactsList() {
      const self = this;
      self.isLoadingUserData = true;
      const userToken = localStorage.getItem("token");
      const payload = JSON.parse(atob(userToken.split(".")[1]));
      let role = this.userRole;
      if (role === "") {
        self.userData = {};
        setTimeout(() => {
          self.navLayout = self.setNavLayout();
          self.filterLayout = self.setFilterLayout();
          self.toolbarLayout = self.setToolbarLayout();
        }, 500);
      } else if (
        role === "developer" ||
        role === "marketing" ||
        role === "marketingv2" ||
        role === "coordinator"
      ) {
        this.getUserByIdStore().then(res => {
          if (res.Message) {
            this.dialog = true;
            this.serverMessage = res.Message;
          }
          self.userData = res.User;
          setTimeout(() => {
            self.navLayout = self.setNavLayout();
            self.filterLayout = self.setFilterLayout();
            self.toolbarLayout = self.setToolbarLayout();
          }, 500);
          self.isLoadingUserData = false;
        });
      } else {
        this.getUserByIdStore().then(res => {
          if (res.Message) {
            this.dialog = true;
            this.serverMessage = res.Message;
          }
          localStorage.setItem("contactId", res.contact._id);

          let moduleQuoteTemp = "off";
          if (res.User) {
            if (res.User.real_estate_group[0]) {
              if (res.User.real_estate_group[0].moduleQuote) {
                moduleQuoteTemp = res.User.real_estate_group[0].moduleQuote;
              }
            }
          }
          localStorage.setItem("moduleQuote", moduleQuoteTemp);
        });
        this.fetchContactStore().then(res => {
          self.contactsList = res.ContactsList;
          self.userData = self.contactsList.filter(l => {
            return l._id === payload.contact_id;
          })[0];
          self.userData.user_id = payload.sub;
          setTimeout(() => {
            self.navLayout = self.setNavLayout();
            self.filterLayout = self.setFilterLayout();
            self.toolbarLayout = self.setToolbarLayout();
          }, 500);
          self.isLoadingUserData = false;
        });
      }
    },
    showDetails(item) {
      this.userToUpdate = item;
      this.$store.state.userData = item;
      this.showUpdateUserModal = true;
    },
    onCloseUserToUpdatetModal(data, dateEdited) {
      this.userToUpdate = null;
      this.showUpdateUserModal = false;
      if (dateEdited === "password") {
        this.logOut();
      } else {
        if (data !== null) {
          this.userData.name = data.name;
          this.userData.email = data.email;
        }
      }
    },
    getLeadsPerformance(data) {
      if (data && data.performance !== undefined) {
        return {
          total: data.performance.total,
          active: data.performance.active,
          defeated: data.performance.defeated
        };
      } else {
        return {
          total: 0,
          active: 0,
          defeated: 0
        };
      }
    },
    // pasar a una librería
    getSafeNestedField(fn, defaultVal) {
      try {
        return fn();
      } catch (e) {
        return defaultVal;
      }
    },
    logOut() {
      LogOut().then(() => {
        location.reload();
      });
    },
    // define el layout nav dependiendo del rol de usuario
    setNavLayout() {
      if (this.userRole === "admin") return "AdminNav";
      if (this.userRole === "developer") return "DeveloperNav";
      if (this.userRole === "broker") return "BrokerNav";
      if (this.userRole === "root") return "RootNav";
      if (this.userRole === "marketing") return "MarketingNav";
      if (this.userRole === "marketingv2") return "MarketingV2Nav";
      if (this.userRole === "coordinator") return "CoordinatorNav";
      return "BrokerNav";
    },
    setTitleNavBar() {
      try {
        const titlePage = document.title;
        const charDivisor = titlePage.indexOf("|");
        // contemplar espacio | y espacio en blanco
        return titlePage.substr(charDivisor + 2, titlePage.length);
      } catch (error) {
        return "";
      }
    },
    setFilterLayout() {
      let path = this.$route.path;
      if (path.includes("lead")) {
        return "LeadsFilter";
      }
      if (path.includes("property")) {
        return "";
      }
      return "";
    },
    setToolbarLayout() {
      let routeName = this.$route.name;
      if (
        [
          "AdminLeadsView",
          "BrokerLeadsView",
          "DeveloperLeadsView",
          "MarketingLeadsView",
          "MarketingV2LeadsView"
        ].includes(routeName)
      ) {
        return "LeadsToolbar";
      }
      return "";
    },
    getLeadsPostponend(stats) {
      if (stats.leadPostponed) {
        return stats.leadPostponed[0].count;
      } else {
        return 0;
      }
    }
  }
};
</script>
<style>
.v-application {
  background: #ffffff !important;
}
.margin-tool-bary {
  margin-top: 50px;
}
</style>

<style scoped>
.center-me {
  position: absolute;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.filter-glows {
  padding: 13px;
  border-radius: 50%;
  background-color: rgba(255, 236, 131, 0);
  box-shadow: 0 0 10px 5px rgb(255, 236, 131),
    /* inner white */ 0 0 25px 10px rgba(255, 251, 0, 0.597),
    /* middle magenta */ 0 0 35px 20px rgb(252, 255, 76); /* outer cyan */
}
</style>
