<template>
  <v-dialog v-model="dialog" persistent max-width="550">
    <v-card>
      <v-card-title class="text-h6">
        {{ title }}
        <v-spacer></v-spacer>
        <v-icon color="red">
          {{ icon }}
        </v-icon>
      </v-card-title>
      <v-divider class="mx-4"></v-divider>
      <v-card-text class="ma-3">
        <p class="text-body-1">
          {{ message }}
        </p>
        <small
          >Si el problema persiste, por favor contacte a soporte técnico.</small
        >
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text outlined rounded @click="logout">
          {{ button }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { LogOut } from "@/api/user-api.js";
export default {
  name: "ModalInformation",
  props: {
    dialog: Boolean,
    messageServer: String
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return this.messageServer === "Error: sesión no válida."
        ? "Se ha cerrado la sesión"
        : "Cuenta deshabilitada";
    },
    message() {
      return this.messageServer === "Error: sesión no válida."
        ? "Su sesión ha sido cerrada, por favor inicie sesión nuevamente."
        : "Su cuenta ha sido deshabilitada, por favor contacte a soporte técnico.";
    },
    icon() {
      return this.messageServer === "Error: sesión no válida."
        ? "mdi-alert"
        : "mdi-account-off";
    },
    button() {
      return this.messageServer === "Error: sesión no válida."
        ? "Iniciar Sesión"
        : "Aceptar";
    }
  },
  methods: {
    async logout() {
      await LogOut();
      location.reload();
    }
  }
};
</script>
